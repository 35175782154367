import 'core-js/es/object/entries';
import 'core-js/es/object/values';
import 'core-js/es/promise';

(new Promise((resolve, reject) => {
    if (! ('IntersectionObserver' in window &&
        'IntersectionObserverEntry' in window &&
        'intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
        import(/* webpackChunkName: "polyfills" */'./polyfills').then(resolve);
    } else {
        resolve();
    }
})).then(() => {
import(/* webpackChunkName: "bootstrap", webpackPreload: true */ './bootstrap');
});